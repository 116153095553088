* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
}

html,
body,
#root,
#root > div {
  height: 100%;
}

body {
  position: relative;
  color: #4d4d4d;
}

.nav-link {
  border-bottom: 2px solid transparent;
}

.anton-regular {
  font-family: "Anton", sans-serif;
  font-style: normal;
}

@media print {
  body, html {
    height: auto !important;
    overflow: visible !important;
  }

  div {
    break-inside: avoid;
  }

  .no-print {
      display: none;
  }

  .printable-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    overflow: visible !important;
  }

  .scrollable-content {
    overflow: visible !important;
  }
}